import React, { useEffect, useState, useRef } from "react"
import { navigate } from "gatsby"
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"


/* Import Page Component(s) */
import ProfilePage from "~components/pages/profile/profilePage"
// import VipPage from "~components/pages/vip/vipPage/vipPage"
// import RegisterForm from "~components/pages/vip/registerForm/registerForm"
// import LoginForm from "~components/pages/vip/loginForm/loginForm"

import ApplicationForm from '~components/pages/profile/applicationForm'


/* Import Local Style(s) */
import "./apply.scss"

const Apply = ({ location }) => {

  return (
    <ProfilePage title="Apply for VIP Status" header={"Apply for VIP Status"} location={location}>
      <ApplicationForm path={"/vips/intake"} surveyRequired={true} />
    </ProfilePage>
  )
}

export default Apply
